import MultipleInstallments from 'components/Cart/Price/MultipleInstallments';
import WithoutInstallment from 'components/Cart/Price/WithoutInstallment';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { enPaymentType } from 'enums/enPaymentType';
import { enTerminalMethod } from 'enums/enTerminalMethod';
import React from 'react';
import { memo } from 'react';
import { useObservable } from 'react-use-observable';
import { cartService } from 'services/CartService';
import { currencyService } from 'services/CurrencyService';
import { paymentService } from 'services/PaymentService';
import { isInternationalCurrency } from 'shared/utils/isInternationalCurrency';

const ProductPrice: React.FC = () => {
  const [cart] = useObservable(() => cartService.getCart(), []);
  const [payment] = useObservable(() => paymentService.getCart(), []);
  const [isMultipleCards] = useObservable(() => paymentService.isMultipleCreditCards(), []);
  const [currency] = useObservable(() => currencyService.getCurrency(), []);

  if (!cart || !payment) {
    return null;
  }

  const { paymentMethod, method } = payment.payment[0];
  const isTerminal = paymentMethod === enPaymentMethod.TERMINAL && method === enTerminalMethod.CREDIT;

  const numberInstallments = payment.payment[0].numberInstallments;
  const isWithInstallments = isTerminal || [enPaymentMethod.CREDIT_CARD, enPaymentMethod.ONE_CLICK_BUY].includes(payment.payment[0].paymentMethod);

  const isMultipleInstallments =
    [enPaymentType.NORMAL, enPaymentType.ECOMMERCE].includes(cart.config.paymentType) &&
    cart.config.paymentType !== enPaymentType.DONATION &&
    cart.config.enableCreditcard &&
    isWithInstallments &&
    numberInstallments > 1 &&
    payment.payment.length == 1 &&
    !isInternationalCurrency(currency) &&
    !isMultipleCards;

  return <>{isMultipleInstallments ? <MultipleInstallments cart={cart} /> : <WithoutInstallment cart={cart} />}</>;
};
export default memo(ProductPrice);
