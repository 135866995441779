import React from 'react';
import { BaseComponent, IStateBase } from 'components/BaseComponent';
import Grid from '@material-ui/core/Grid/Grid';
import PreviousStep from 'components/Cart/PreviousStep';
import { IProductData } from 'interfaces/IProductData';
import { cartService } from 'services/CartService';
import Translate from 'components/Translate';
import MultipleInstallments from 'components/Cart/Price/MultipleInstallments';
import WithoutInstallment from 'components/Cart/Price/WithoutInstallment';
import { enStep } from 'components/Cart/Templates/Mobile/MultiStep';
import Coupon from 'components/Cart/Coupon';
import { ICartModel } from 'interfaces/ICartModel';
import { paymentService } from 'services/PaymentService';
import CouponPrice from 'components/Cart/Coupon/CouponPrice';
import { Subscription } from 'components/Cart/Price/Subscription';
import { enPaymentType } from 'enums/enPaymentType';
import FreeShipping from 'components/Cart/Price/FreeShipping';
import { currencyService } from 'services/CurrencyService';
import { enPaymentMethod } from 'enums/enPaymentMethod';
import { enCurrency } from 'enums/enCurrency';
import { isInternationalCurrency } from 'shared/utils/isInternationalCurrency';

interface IState extends IStateBase {
  cart: IProductData;
  payment: ICartModel;
  currency: enCurrency;
  step: enStep;
  isMultipleCards: boolean;
}

export default class HeaderStep extends BaseComponent<IState> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ payment: null }, paymentService.getCart());
    this.observeState({ currency: enCurrency.BRL }, currencyService.getCurrency());
    this.observeState({ isMultipleCards: false }, paymentService.isMultipleCreditCards());
  }

  render() {
    if (!this.state) {
      return null;
    }

    const { cart, payment, currency, isMultipleCards } = this.state;
    const numberInstallments = payment.payment[0].numberInstallments;
    const isWithInstallments = [enPaymentMethod.CREDIT_CARD, enPaymentMethod.ONE_CLICK_BUY].includes(payment.payment[0].paymentMethod);
    return (
      <Grid className="header" container alignItems="center">
        <Grid item xs={6}>
          <PreviousStep step={this.props.step} setStep={this.props.setStep} />
          {this.props.step == enStep.payment && <Coupon />}
        </Grid>
        <Grid item xs={6} className={cart.config.paymentType == enPaymentType.FREE ? 'free' : ''}>
          <CouponPrice />

          {cart.config.paymentType === enPaymentType.FREE && <Translate term={'Gratuito'} />}

          {[enPaymentType.NORMAL, enPaymentType.ECOMMERCE].includes(cart.config.paymentType) &&
            cart.config.paymentType !== enPaymentType.DONATION &&
            (cart.config.enableCreditcard &&
            isWithInstallments &&
            numberInstallments > 1 &&
            payment.payment.length == 1 &&
            !isInternationalCurrency(currency) &&
            !isMultipleCards ? (
              <MultipleInstallments cart={cart} />
            ) : (
              <WithoutInstallment cart={cart} />
            ))}

          <FreeShipping config={cart.config} />

          {cart.config.paymentType === enPaymentType.SUBSCRIPTION && <Subscription payment={payment} cart={cart} />}
        </Grid>
      </Grid>
    );
  }
}
