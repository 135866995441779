/* eslint-disable max-lines */
import { BaseComponent } from 'components/BaseComponent';
import { ICartModel } from 'interfaces/ICartModel';
import { IProductConfig } from 'interfaces/IProductData';
import React from 'react';
import { cartService } from 'services/CartService';
import { paymentService } from 'services/PaymentService';

import { Header } from './Header';
import MultiStep from './MultiStep/index';
import Hidden from '@material-ui/core/Hidden';
import { ITemplateState, IPayProps } from 'components/Cart';
import SingleStep from './SingleStep/index';
import OneClickBuy from './OneClickBuy';
import TopBar from 'components/TopBar';
import ExitPopUp from 'components/ExitPopUp';
import { enPaymentType } from 'enums/enPaymentType';
import { HeaderProductLogo } from './HeaderProductLogo';
import { WithStyles } from 'shared/decorators/withStyles';
import { configService } from 'services/ConfigService';

interface IState extends ITemplateState {
  config: IProductConfig;
  model: ICartModel;
}

@WithStyles(() => ({
  component: {
    overflow: 'hidden',
    backgroundColor: '#FAFAFA !important',

    '&.backgroundImage': {
      backgroundSize: 'cover'
    },

    '& .container': {
      maxWidth: '1024px',
      margin: 'auto',

      '&.padding-top-language': {
        paddingTop: '32px'
      }
    },

    '& .chk-cityState__state, & .chk-cityState__city, & .chk-userAddressInternational__country': {
      '& input': {
        border: '1px solid #d9d9d9',
        padding: '10px 8px 7px 8px'
      }
    },

    '& .field.normal input': {
      border: '1px solid #d9d9d9'
    },

    '& input': {
      fontSize: '18px',
      height: '28px',
      margin: '5px 0'
    },

    '& .chk-has-number': {
      fontSize: '0.75rem',
      color: '#636871'
    },

    '& .chk-userAddress__container': {
      '& .chk-userAddress__district, & .chk-userAddress__complement': {
        padding: '0 4px 4px 4px'
      }
    },

    '& .chk-stepPayment__userInfo': {
      display: 'flex',
      alignItems: 'center',
      margin: '0 0 20px 0',

      '& .chk-stepPayment__checkIcon': {
        marginRight: '2px',

        '& svg': {
          width: '12px',
          fill: '#009358'
        }
      },

      '& .chk-stepPayment__emailInfo': {
        color: '#596375',
        fontSize: '0.75rem',
        display: 'flex',

        '& a': {
          color: '#F59211'
        },

        '& span': {
          maxWidth: '140px',
          display: 'inlineBlock',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          marginRight: '4px'
        }
      }
    },

    '& .field-select': {
      position: 'relative',
      height: 50,
      top: '5px',
      border: '1px solid #d9d9d9',
      borderRadius: 2,
      fontSize: '0.75rem',
      //boxShadow: 'inset -1px 1px 2px 0 rgba(168, 168, 168, 0.45)',
      boxShadow: 'none',
      padding: '7px 10px 7px 10px'
    },

    '& .chk-stepUser__btnContinue, & .chk-stepUser__btnFinish, & .chk-stepUser__btnGetForFree': {
      width: '100%',
      backgroundColor: '#00b356',
      borderRadius: '4px',
      color: '#FFFFFF',
      fontSize: '1rem',
      fontWeight: 400,
      height: '45px',
      textTransform: 'unset'
    },

    '& .chk-stepUser__btnFinish': {
      '& svg': {
        width: '20px',
        fill: '#FFF',
        marginRight: '10px'
      }
    },

    '& .chk-stepTicket__btnContinue': {
      backgroundColor: '#00b356',
      borderRadius: '4px',
      color: '#FFFFFF',
      height: '45px',
      textTransform: 'unset'
    },

    '& .chk-creditCardForm__saveCard, & .checkbox_wallet': {
      color: '#636871',
      '& label': {
        margin: 0
      },
      '&.checkbox_singleStep, &': {
        '& span': {
          fontSize: '0.75rem'
        }
      },

      '& .checkbox': {
        margin: '0 6px 0 0',
        width: '20px',
        height: '20px',

        '& svg': {
          color: '#009358',
          width: '25px',
          height: 'auto'
        }
      }
    },

    '& .chk-stepPayment__productInfo': {
      backgroundColor: '#F8F8F8',
      padding: '8px 16px',

      '& .chk-stepPayment__productTitle': {
        fontSize: '0.875rem',
        margin: 0,
        textAlign: 'center'
      }
    },

    '& .chk-stepPayment__configCurrencyLabel': {
      fontSize: '0.75rem',
      color: '#596375',
      letterSpacing: '0.5px'
    },

    '& .chk-stepPayment__priceHeader': {
      marginBottom: '3px',
      paddingBottom: '4px'
    },

    '& .chk-oneClickBuy__productPrice .chk-full': {
      textAlign: 'right',
      '& .chk-number-price': {
        fontSize: '1.25rem',
        fontWeight: 700
      },
      '& .chk-small-price': {
        color: '#585858',
        fontSize: '0.625rem',
        fontWeight: 600,
        letterSpacing: '0.42px'
      }
    },

    '& .chk-stepPayment__pin': {
      width: '100%'
    },

    '& .chk-no-fee': {
      fontWeight: 'bold',
      fontSize: '14px'
    },

    '& .chk-stepPayment__productPrice, & .chk-oneClickBuy__productPrice': {
      '& .chk-parts': {
        textAlign: 'right',

        '& .chk-number-parts': {
          fontSize: '0.75rem',
          fontWeight: 600,
          color: '#585858'
        },

        '& .chk-number-price': {
          fontSize: '1.25rem',
          fontWeight: 700
        },

        '& .chk-small-price': {
          fontSize: '0.625rem',
          letterSpacing: '0.42px',
          color: '#585858',
          fontWeight: 600
        },

        '& .chk-no-fee': {
          fontWeight: 'bold',
          fontSize: '14px'
        }
      }
    },

    '& .chk-full': {
      '@media screen and (max-width: 960px)': {
        textAlign: 'right'
      },

      textAlign: 'left',

      '& .chk-number-price': {
        fontSize: '1.25rem',
        fontWeight: 700
      },

      '& .chk-full-price': {
        fontSize: '1.2rem',
        fontWeight: 600,
        padding: 0
      },

      '& .chk-small-price': {
        fontSize: '0.75rem'
      }
    },

    '& .chk-coupon-code-label': {
      margin: '12px 0 0'
    },

    '& .chk-multiple-cards': {
      display: 'flex',
      justifyContent: 'center'
    },

    '& .chk-searchCEP__button': {
      position: 'relative',
      fontWeight: 500,
      textDecoration: 'underline',
      color: '#F59211',
      float: 'right',
      fontSize: '0.75rem',
      display: 'inline-block',
      textAlign: 'right',
      marginBottom: '-15px',
      cursor: 'pointer',
      zIndex: 1000,
      outline: 'none',
      top: '7px',

      '@media screen and (max-width: 959px)': {
        top: '-7px'
      }
    },

    '& .chk-coupon': {
      '@media screen and (min-width: 960px)': {
        '& .field.normal label[data-shrink="false"], & .field.normal label[data-shrink="true"]': {
          transform: 'scale(1) translate(0, 4px)'
        }
      },

      '& .chk-coupon-code': {
        '& .field.normal': {
          '& div': {
            marginTop: 0
          },

          '& input': {
            padding: '7px 4px 8px 4px',
            fontSize: '0.9375rem',

            '@media screen and (max-width: 768px)': {
              fontSize: '0.75rem'
            }
          }
        }
      },

      '& .chk-coupon-set': {
        height: '20px',

        '@media screen and (max-width: 768px)': {
          height: '36px'
        }
      }
    },

    '& .chk-button-coupon': {
      fontSize: '0.75rem',
      letterSpacing: '0.5px',
      textAlign: 'left'
    },

    '& .chk-config-currency, & .chk-config-button': {
      width: '100%'
    },

    '& .chk-config-currency': {
      margin: 0,
      textAlign: 'right',

      '& .chk-config-button': {
        borderRadius: '2px',
        padding: '5px'
      }
    },

    '& .grid': {
      '& .chk-stepUser__btnContinueGrid': {
        padding: 0
      }
    },

    '& .chk-payment': {
      padding: 0,
      width: '100%'
    },

    '& .chk-payment-flags': {
      margin: 0
    },

    '& .chk-installments__installmentFieldSelect': {
      marginTop: '8px',
      marginBottom: 0
    },

    '& .chk-installments__value': {
      fontSize: '0.75rem',
      margin: '8px 0 0 0',
      color: '#585858',

      '& span': {
        fontWeight: 700,
        marginRight: '8px'
      },

      '& a': {
        fontSize: '0.666rem',
        textDecoration: 'underline',
        color: '#333',
        cursor: 'pointer'
      }
    },

    '& .chk-paymentForm__paypalMessage, & .chk-paymentForm__boletoMessage': {
      fontSize: '0.75rem',
      display: 'inlineBlock',
      textAlign: 'center',
      margin: 'auto',
      maxWidth: 500
    },

    '& .chk-paymentForm__boletoMessage': {
      '& p': {
        margin: '8px 0'
      }
    },

    '& .step': {
      padding: '8px 16px',
      backgroundColor: '#fff',
      '& .chk-multiStep__productName': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '50px',
        width: '100%',
        '& .chk-logo__content-step-payment': {
          marginTop: '-50px'
        },
        '& .product-name': {
          marginTop: '12px',
          textAlign: 'center'
        }
      },
      '& .grid': {
        display: 'block',
        position: 'relative'
      }
    },

    '& .product-name': {
      display: 'block',
      margin: 0,
      textAlign: 'left',
      wordWrap: 'break-word',
      width: '100%'
    },

    '& .banner-header': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '52px',

      '@media screen and (max-width: 959px)': {
        paddingTop: '32px'
      },

      '& .header': {
        height: '280px',
        width: '100%',
        maxWidth: '1024px',

        '@media screen and (max-width: 768px)': {
          height: '100px',
          margin: 0
        },

        '& img': {
          height: '100%',
          width: '100%',
          objectFit: 'cover',
          border: '1px solid #e8e7e7',
          borderRadius: '4px',

          '@media screen and (max-width: 768px)': {
            maxHeight: '100px'
          }
        }
      }
    },

    '& .chk-sessionProductLogo': {
      backgroundColor: '#F8F8F8',

      '& .chk-headerProductLogo': {
        backgroundColor: '#F8F8F8',
        display: 'flex',
        height: '100px',
        margin: '34px 16px 0 16px',

        '& img': {
          height: '100px',
          objectFit: 'cover',
          minWidth: '100px',
          marginRight: '15px'
        },

        '& .title': {
          display: 'flex',
          alignItems: 'center',
          width: '100%'
        }
      }
    },

    '& h1': {
      fontSize: '1.125rem',
      fontWeight: 600,
      lineHeight: '24px',
      textAlign: 'center',
      width: '100%'
    },

    '& .description': {
      color: 'rgb(148, 148, 148)',
      fontSize: '1rem',
      textAlign: 'center'
    },

    '& .chk-oneClickBuy__product': {
      padding: '16px',

      '& .chk-oneClickBuy__title': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '& h1': {
          display: 'inlineBlock',
          fontSize: '0.875rem',
          lineHeight: '19px',
          textAlign: 'left',
          width: '100%',
          wordBreak: 'break-word'
        }
      }
    },

    '& .chk-oneClickBuy__container': {
      '@media screen and (max-width: 768px)': {
        maxWidth: '100%',
        width: '100%',
        margin: 0,
        border: 'none',
        marginTop: '30px'
      },

      border: '1px solid #e8e7e7',
      borderRadius: '4px',
      backgroundColor: '#FFF',
      maxWidth: '370px',
      width: '370px',
      margin: '33px 0 0 0',

      '& .chk-askAdress__content': {
        '& .form': {
          padding: '20px 0 4px 0',
          width: '100%'
        },

        '& .chk-btn-download': {
          maxHeight: '60px',
          padding: '14px',
          marginTop: '8px',
          width: '100%'
        },

        '& .addressAfter': {
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',

          '& .icon': {
            marginRight: '20px'
          },

          '& .chk-paragraph': {
            '& .title': {
              color: '#000',
              fontSize: '1em',
              textAlign: 'left',
              marginBottom: '5px'
            },

            '& .label': {
              fontSize: '0.8em',
              color: '#000',
              marginTop: 0
            }
          }
        }
      },

      '& .chk-askAdress__oneClickBuy': {
        '& .form': {
          padding: '0 20px'
        },

        '& .addressAfter': {
          padding: '20px'
        },

        '& .chk-btn-download': {
          marginTop: '23px',
          borderRadius: '0 0 4px 4px'
        },

        '& .chk-searchCEP__button': {
          marginBottom: '-10px'
        }
      },

      '& .chk-oneClickBuy__content': {
        borderTop: 'none',
        padding: '16px',

        '& .chk-oneClickBuy__form': {
          '& .paymentButton': {
            margin: '10px 0'
          }
        }
      }
    },

    '& .chk-searchCEP-modal': {
      width: '100vw',
      height: '95vh',

      '& .field': {
        '& input': {
          padding: '24px 8px 8px 8px',
          borderRadius: '2px',
          border: '1px solid #d9d9d9',
          fontSize: '0.9375rem',
          appearance: 'none'
        },

        '& label': {
          fontSize: '0.875rem',
          zIndex: 1
        },

        '& label[data-shrink="true"]': {
          transform: 'translate(8px, 20px) scale(0.75)'
        },

        '& label+div': {
          marginTop: '8px'
        }
      }
    },

    '& .chk-couponPrice': {
      '@media screen and (max-width: 768px)': {
        textAlign: 'right'
      }
    },

    '& .brand-icon': {
      marginTop: '44px',

      '@media screen and (max-width: 959px)': {
        marginTop: '35px'
      }
    },

    '& .cepNotFound': {
      color: '#F59211',
      fontSize: '0.775rem',
      display: 'flex',

      '& svg': {
        '@media screen and (max-width: 600px)': {
          width: '30px'
        },

        height: '15px',
        width: '15px',
        margin: '0 5px 0 5px'
      }
    },

    '& .chk-order-bump': {
      marginBottom: '8px'
    },

    '& .summary-cart': {
      margin: '8px 0'
    }
  }
}))
export default class MobileTheme extends BaseComponent<IState, IPayProps> {
  componentDidMount() {
    this.observeState({ cart: null }, cartService.getCart());
    this.observeState({ config: null }, configService.getConfig());
    paymentService.setAllowPayment().subscribe();
    this.bindModel(paymentService.getCart());
  }

  render() {
    if (!this.state || !this.state.cart) {
      return null;
    }

    const { onPay, classes } = this.props;
    const { cart, config } = this.state;

    const topBanner = config.template.media.bannerTop;
    const topBannerUrl = config.termsUrl ?? config.template.media.bannerTopUrl ?? null;
    const product = cart.products[0];

    return (
      <div className={`${classes.component} theme-background-image backgroundImage '`}>
        <TopBar />

        {cart.config.isOneClick ? (
          <OneClickBuy onPay={onPay} />
        ) : (
          <div className={`container ${!topBanner && 'padding-top-language'}`}>
            <Hidden mdUp>
              {topBanner && config.paymentType !== enPaymentType.DONATION && <Header topBanner={topBanner} topBannerUrl={topBannerUrl} />}

              {config.paymentType == enPaymentType.DONATION && <HeaderProductLogo logoUrl={config.logo} title={cart.content.title} />}
              <MultiStep product={product} onPay={onPay} />
            </Hidden>
            <Hidden smDown>
              {config.exitModal && !paymentService.hasPayment() && <ExitPopUp />}
              {topBanner && <Header topBanner={topBanner} topBannerUrl={topBannerUrl} />}
              <SingleStep onPay={onPay} />
            </Hidden>
          </div>
        )}
      </div>
    );
  }
}
